<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Experiment 4: Prelab Question 1</h2>

      <p class="mb-0">Make a sketch of the graph shown below and clearly label the following:</p>

      <p class="mb-2">
        <v-list>
          <li class="pl-8">Ordinate</li>
          <li class="pl-8">Abscissa</li>
          <li class="pl-8">Origin</li>
          <li class="pl-8">y-intercept</li>
          <li class="pl-8">Data point at x = 7. Include corresponding y coordinate.</li>
        </v-list>
      </p>

      <p>Attach your labelled graph below which will be graded by your TA.</p>

      <apex-chart
        type="area"
        height="400"
        width="600"
        class="mb-5"
        :options="plotOptions"
        :series="plotData"
      />

      <p><b>Accepted file types</b>: PDF, JPG, JPEG, and PNG</p>

      <v-row>
        <v-file-input v-model="attachments" multiple />
      </v-row>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {linspace} from '../../utils/math';
import VueApexCharts from 'vue-apexcharts/dist/vue-apexcharts';

export default {
  name: 'OleMissExperiment9PrelabQuestion1',
  components: {
    StembleLatex,
    ApexChart: VueApexCharts,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      plotOptionsStatic: {
        chart: {
          shadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1,
          },
          stroke: {
            curve: 'straight',
          },
          toolbar: {
            show: false,
          },
        },
        tooltip: {
          enabled: false,
        },
        colors: ['#8B0000', '#474747'],
        dataLabels: {
          enabled: false,
        },
        grid: {
          borderColor: '#c9c9c9',
          yaxis: {
            lines: {
              show: true,
            },
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        markers: {
          size: 0,
        },
        yaxis: {
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#78909C',
            height: 6,
            style: {
              fontSize: '16px',
              fontFamily: 'Times New Roman',
              fontWeight: 600,
            },
          },
          labels: {
            formatter(val) {
              return val.toFixed(1);
            },
            style: {
              fontSize: '14px',
              fontFamily: 'Times New Roman',
              fontWeight: 600,
            },
          },
          tickAmount: 10,
          tickPlacement: 'between',
          min: 0,
          max: 40,
        },
        xaxis: {
          labels: {
            formatter(val) {
              return val.toFixed(0);
            },
            style: {
              fontSize: '14px',
              fontFamily: 'Times New Roman',
              fontWeight: 600,
            },
          },
          tickAmount: 15,
          tickPlacement: 'between',
          min: 0,
          max: 15,
        },
      },
      attachments: [],
    };
  },

  computed: {
    slope() {
      return this.taskState.getValueBySymbol('slope').content;
    },
    yInt() {
      return this.taskState.getValueBySymbol('yInt').content;
    },
    xValues() {
      return linspace(0, 15, 1501);
    },
    plotFunction() {
      // The proxy value / 200 is just so that student's can't look at the task state in the server's response
      return (t) => this.slope.toFloat() * t + this.yInt.toFloat();
    },
    plotData() {
      const data = this.xValues.map((t) => [t, this.plotFunction(t)]);
      return [
        {
          name: '1',
          data,
        },
      ];
    },
    plotOptions() {
      return this.plotOptionsStatic;
    },
  },
};
</script>
